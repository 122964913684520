// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ToolsService from '@services/tools';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';

// components
import CellText from '@components/cells/cell-text';
import CellPair from '@components/cells/cell-pair';
import CellExchange from '@components/cells/cell-exchange';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const tools = useTools();
    // i18n
    const { t } = useI18n();

    // vars
    const progress = ref();
    const pairsInfo = ref();
    const generalInfo = ref();
    const autoUpdate = ref(false);
    const dataForm = reactive({
        stat_period: 1,
        exchange: -1,
    });

    watch([
        () => dataForm.stat_period,
        () => dataForm.exchange,
    ], () => {
        getStatsFullInfo();
    });

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const statPeriodsOptions = computed(() => tools.statPeriods.map(el => ({
        label: el.title,
        value: +el.id,
        ...el,
    })));

    const columns = computed(() => [
        {
            title: '#',
            key: 'i',
        }, {
            title: tools.localization['tools/stats/pairs_info/pair'],
            key: 'pair',
        }, {
            title: tools.localization['tools/stats/pairs_info/exchange'],
            key: 'exchange',
        },
    ]);

    const tableColumns = computed(() => 
        columns.value.map(el => (
            {
                key: el.key,
                order: false,
                render: (row, i) => {
                    if (el.key === 'i') {
                        return gl.isMobile
                            ? h('div', {
                                class: 'text-center text-xs font-semibold text-gray-600 dark:',
                            }, i + 1)
                            : h(CellText, {
                                data: i + 1,
                                classes: 'text-center',
                            });
                    } else if (el.key === 'exchange') {
                        return h(CellExchange, {
                            data: row[el.key],
                        });
                    } else if (el.key === 'pair') {
                        return h(CellPair, {
                            coin: row[el.key],
                            data: row[el.key],
                            dataFooter: row[el.key],
                        });
                    }
                },
                ...el,
            }
        )),
    );

    const getStatsFullInfo = async (showLoading = true) => {
        // if (showLoading)
        //     gl.showLoading = true;

        try {
            const data = ( await ToolsService.getStatsFullInfo(dataForm) ).data;

            pairsInfo.value = data.pairsInfo;
            generalInfo.value = data.generalInfo;

        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        // if (showLoading)
        //     gl.showLoading = false;
    };

    const doneTimer = async () => {
        await getStatsFullInfo();

        progress.value.start();
    };

    const setAutoUpdate = (e) => {
        autoUpdate.value = e;
    };

    onMounted( async () => {
        await getStatsFullInfo();
    });

    return {
        gl,
        refs,
        tools,
        progress,
        dataForm,
        pairsInfo,
        autoUpdate,
        generalInfo,
        tableColumns,
        exchangeOptions,
        statPeriodsOptions,
        doneTimer,
        setAutoUpdate,
        getStatsFullInfo,
    };
}