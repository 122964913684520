<template>
<section class="px-3">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        content-style="padding: 0;"
        :segmented="{
          content: true,
        }">
        <template #header>
            <Progress v-if="autoUpdate" class="mb-4" ref="progress" @done="doneTimer" />
            <div class="flex justify-between items-center">
                <div class="text-xs text-left">{{ tools.localization?.['tools/stats/general_info/title'] }}</div>

                  <div class="flex justify-between items-center ">
                      <rb-checkbox
                          :label="refs.localization.autorefresh.autorefresh_label"
                          :checked="autoUpdate"
                          @update:checked="setAutoUpdate" />
                      <n-button
                          size="tiny"
                          class="ml-2 rounded-md text-white/90"
                          :color="gl.mainColor"
                          @click="getStatsFullInfo">
                          {{ refs.localization.autorefresh.autorefresh_btn }}
                      </n-button>
                  </div>
              </div>
        </template>
        <template #default>
            <div class="flex flex-row flex-wrap w-full px-3">
                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/bots/active'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.bots.active }}</span> ,
                    {{ tools.localization['tools/stats/general_info/bots/total'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.bots.total }}</span>
                </div>
                <n-divider class="my-0" />

                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/finished_cycles/day'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.finished_cycles.day }}</span> ,
                    {{ tools.localization['tools/stats/general_info/finished_cycles/month'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.finished_cycles.month }}</span>
                </div>
                <n-divider class="my-0" />

                <div class="text-xs py-4">
                    {{ tools.localization['tools/stats/general_info/users/day'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.users.day }}</span> ,
                    {{ tools.localization['tools/stats/general_info/users/total'] }}
                    <span class="text-main font-bold text-md">{{ generalInfo?.users.total }}</span>
                </div>
            </div>
        </template>
    </n-card>

    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md mt-4"
        content-style="padding: 0;"
        :segmented="{
          content: true,
        }">
        <template #header>
            <div class="flex w-full items-center">
                <div class="text-xs text-left" v-html="tools.localization?.['tools/stats/pairs_info/title']"></div>
                <div class="flex flex-grow justify-end">
                    <rb-select
                        :options="exchangeOptions"
                        v-model:value="dataForm.exchange" />
                    <rb-select
                        class="ml-4"
                        :options="statPeriodsOptions"
                        :defaultValue="false"
                        v-model:value="dataForm.stat_period" />
                </div>
            </div>
        </template>
        <template #default>
            <n-data-table
                :bordered="false"
                :single-line="false"
                :columns="tableColumns"
                :data="pairsInfo" />
        </template>
    </n-card>
</section>
</template>

<script>
// general
import general from './general';

// UI
import {
    NCard,
    NButton,
    NDivider,
    NDataTable } from 'naive-ui';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import Progress from '@components/base/progress.vue';

export default {
    name: 'tools-stats-desktop',
    components: {
        NCard,
        NButton,
        RbSelect,
        Progress,
        NDivider,
        NDataTable,
        RbCheckbox,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(b) {
  color: #4949D9;
}

.table-card :deep(.n-card__content) {
  padding: 0;
}

:deep(td) {
    padding: 0 !important;

    & > * {
        padding: 4px;
    }
}
</style>